const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://driverpro.dvgeo.app' : 'http://192.168.1.106:3042',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://driverpro.dvgeo.app' : 'http://192.168.1.106:3042',
    api: 'api/',
    apiSocket: 'driverpro::1.2',
    nameDir: 'driverpro',
    package: 'app.dvgeo.driverpro.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyCIrv0dKb6sF6u0-bH_50n7Xo4vCApvFNI',
    appName: 'Driver Pro',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#D1B428',
    colorDark: '#1A1A1A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.driverpro.passenger',
    playStoreDriverId: 'app.dvgeo.driverpro.driver',
    appStorePassengerId: '6450679321',
    appStoreDriverId: '6450679717',
    email: "trujillovip.corpperu@gmail.com",
};
export default config;
