import React from 'react';
//store
import {putSys} from "./store/actions/system.ac";
//models
import {Sys} from "./api/_sys/sysModel";
//libs
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
//views
import IndexSwitch from "./views/index";
import PassengerSwitch from "./views/passenger";
//types
import {StoreDispatchProps, StoreStateProps} from "./store/types";

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

//interfaces
interface OwnProps {

}

//const
//View
const App = (props: Props) => {
    return (
        <Router>
            <Switch>
                <Route
                    path={`/`}
                    component={IndexSwitch}
                    exact
                />
                <Route
                    path={`/app/passenger*`}
                    component={PassengerSwitch}
                    exact
                />
                {/*default*/}
                <Route
                    path={`*`}
                    component={IndexSwitch}
                    exact
                />
            </Switch>
        </Router>
    );
}

const mapStateToProps = (state: StoreStateProps) => ({
    system: state.system,
})

const mapDispatchToProps = {
    //system
    putSys: (sys: Sys) => (putSys(sys)),
}
export default connect<StoreStateProps, StoreDispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(App);
